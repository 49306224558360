import moment from "moment";
import styled from "styled-components";

const FooterComponent = styled.footer`
    width: 100%;

    .footer-octopus{
        list-style: none;
    }

    .container--fluid{
        max-width: 1800px;
    }

    @media screen and (max-width: 1120px) {
        .container--fluid{
            max-width: 100%;
            padding-top: 0px;          
            padding-left:0!important;
            padding-right:0!important;  
        }
    }
`

const InfoApplications = () => {
    return (
        <FooterComponent>
            <div className="container-fluid container--fluid">
                <div className="row">
                    <div className="col-md-2" style={{ marginTop: 30 }}>
                        <div className="logo-team-software d-flex align-items-center justify-content-center">
                            <img src="/img/tensoftware.png" />
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="row">
                            <div className="col-md-4" style={{ marginTop: 30 }}>
                                <ul className="footer-octopus">
                                    <li><b>LIÊN HỆ</b></li>
                                    <li>Email: <a href="/" className="link-footer">info@tengroup.com.vn</a></li>
                                    <li>Số điện thoại:   <a href="/" className="link-footer">028.5432 9067</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="/" className="link-footer">028.5432 9068</a></li>
                                </ul>
                            </div>
                            <div className="col-md-4" style={{ marginTop: 30 }}>
                                <ul className="footer-octopus">
                                    <li><b>ĐỊA CHỈ</b></li>
                                    <li> Số 56, 3122 Đường Phạm Thế Hiển,<br /> Phường 7, Quận 8, Thành phố Hồ Chí Minh</li>
                                </ul>
                            </div>
                            <div className="col-md-3" style={{ marginTop: 30 }}>
                                <ul className="footer-octopus">
                                    <li><b>KẾT NỐI VỚI CHÚNG TÔI</b></li>
                                    <li>
                                        <div className="d-flex" style={{ marginTop: 10 }}>
                                            <a href="https://www.facebook.com/tengroupJSC" target={`_blank`} style={{ marginRight: 20 }}>
                                                <img src="/img/icons/facebook.svg" />
                                            </a>
                                            <a style={{ marginRight: 20 }} >
                                                <img src="/img/icons/youtube.svg" />
                                            </a>
                                            {/* <a>
                                                <img src="/img/icons/instagram.svg" />
                                            </a> */}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-1 qr-code" style={{ marginTop: 30 }}>
                                <img src="/img/qr.jpg" alt="QR Code" style={{ objectFit: 'contain', width: `100%` }} />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="d-flex">
                    <div className="logo-team-software d-flex align-items-center justify-content-center">
                        <img src="/img/tensoftware.png" />
                    </div>
                    <div className="info-footer d-flex">
                        <ul className="footer-octopus">
                            <li><b>LIÊN HỆ</b></li>
                            <li>Email: <a href="/" className="link-footer">info@tengroup.com.vn</a></li>
                            <li>Số điện thoại:   <a href="/" className="link-footer">028.5432 9067</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="/" className="link-footer">028.5432 9068</a></li>
                        </ul>
                        <ul className="footer-octopus">
                            <li><b>ĐỊA CHỈ</b></li>
                            <li> Số 56, 3122 Đường Phạm Thế Hiển,<br /> Phường 7, Quận 8, Thành phố Hồ Chí Minh</li>
                        </ul>
                        <ul className="footer-octopus">
                            <li><b>KẾT NỐI VỚI CHÚNG TÔI</b></li>
                            <li>
                                <div className="d-flex" style={{ marginTop: 10 }}>
                                    <a>
                                        <img src="/img/icons/facebook.svg" style={{ marginRight: 20 }} />
                                    </a>
                                    <a>
                                        <img src="/img/icons/youtube.svg" style={{ marginRight: 20 }} />
                                    </a>
                                    <a>
                                        <img src="/img/icons/instagram.svg" />
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="d-flex align-items-center justify-content-center qr-code">
                        <img src="/img/qr.jpg" style={{ objectFit: 'contain', width: `100%` }} />
                    </div>
                </div> */}
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 text-center" style={{ marginTop: 20 }}>
                        {/* <a className="footer-link" target={`_blank`} href="/TermAndConditions.html">Term condition</a>&nbsp;&nbsp;&nbsp;&nbsp;<a className="footer-link" target={`_blank`} href="/PrivacyPolicy.html">Policy privacy</a> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center" style={{ marginTop: 20, color: '#9B9B9B', fontWeight: 500 }}>
                        {`Copyright@${moment().year()}. All right reserved by TenGroup`}
                    </div>
                </div>
            </div>
        </FooterComponent>
    )
}

export default InfoApplications