import { Fragment, useEffect, useMemo, useState, useRef } from "react";
// import { Container, Row, Col } from "react-bootstrap";
import { Dropdown } from "primereact/dropdown";
import ClientCaptcha from "@/components/captcha";
import { Messages } from 'primereact/messages';
import * as actions from "@/redux/actions/index";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import * as enums from "@/common/enum";
import styled from "styled-components";
import * as fnc from '@/common/function';
import moment from "moment";

const ArticleContent = styled.section`
    padding: 15px 0px;
    margin: 0px;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    background-color: #fdb64c;
    position: relative;

    .container--fluid{
        padding: 0px 11px!important;
        height: 100%;
        max-width: 1800px;
    }   

    .input-group > .input-group-prepend {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        // border: 1px solid #ced4da;
        border-right: unset;
        padding: 0 10px 0 15px;
        height: 49px;
        width: 45px;
    }

    .input-group > .input-group-prepend ~ .form-control {
        border-left: unset;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        height: 49px;
        border: unset;
        font-size: 14px;
    }

    .form-control{
        height: 49px;
        border-radius: 10px;
        font-size: 14px;
        border-color: #DDDDDD;
    }

    .form-control:focus{
        box-shadow: unset;
        outline: unset;
        border: 1px solid #F3941F;
    }

    .input-group {
        border: 1px solid #DDDDDD;
        border-radius: 10px;
    }

    .icon-user{
        background-image: url("data:image/svg+xml,%3Csvg width='17' height='21' viewBox='0 0 17 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.25 8.29419C16.25 12.4252 12.8001 15.8096 8.5 15.8096C4.19987 15.8096 0.75 12.4252 0.75 8.29419C0.75 4.16321 4.19987 0.778809 8.5 0.778809C12.8001 0.778809 16.25 4.16321 16.25 8.29419Z' stroke='black' stroke-width='1.5'/%3E%3Cline x1='1.4165' y1='19.9424' x2='15.5832' y2='19.9424' stroke='black' stroke-width='1.5'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 17px 21px;
        background-position: center center;
    }

    .icon-mail{
        background-image: url("data:image/svg+xml,%3Csvg width='22' height='23' viewBox='0 0 22 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.66683 4.00195H18.3335C19.3418 4.00195 20.1668 4.81373 20.1668 5.80591V16.6296C20.1668 17.6218 19.3418 18.4336 18.3335 18.4336H3.66683C2.6585 18.4336 1.8335 17.6218 1.8335 16.6296V5.80591C1.8335 4.81373 2.6585 4.00195 3.66683 4.00195Z' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20.1668 5.80566L11.0002 12.1195L1.8335 5.80566' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 22px 21.65px;
        background-position: center center;
    }

    .icon-phone{
        background-image: url("data:image/svg+xml,%3Csvg width='21' height='22' viewBox='0 0 21 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.875 2.38574H6.125C5.1585 2.38574 4.375 3.15669 4.375 4.1077V17.8833C4.375 18.8343 5.1585 19.6053 6.125 19.6053H14.875C15.8415 19.6053 16.625 18.8343 16.625 17.8833V4.1077C16.625 3.15669 15.8415 2.38574 14.875 2.38574Z' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.5 16.1611H10.5087' stroke='black' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 21px 20.65px;
        background-position: center center;
    }

    .input-group:focus-within {
        border: 1px solid #F3941F;
    }

    .input-group:focus-within > .input-group-prepend.icon-user {
        background-image: url("data:image/svg+xml,%3Csvg width='17' height='21' viewBox='0 0 17 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.25 8.29419C16.25 12.4252 12.8001 15.8096 8.5 15.8096C4.19987 15.8096 0.75 12.4252 0.75 8.29419C0.75 4.16321 4.19987 0.778809 8.5 0.778809C12.8001 0.778809 16.25 4.16321 16.25 8.29419Z' stroke='%23F3941F' stroke-width='1.5'/%3E%3Cline x1='1.4165' y1='19.9424' x2='15.5832' y2='19.9424' stroke='%23F3941F' stroke-width='1.5'/%3E%3C/svg%3E%0A");
    }

    .input-group:focus-within > .input-group-prepend.icon-mail {
        background-image: url("data:image/svg+xml,%3Csvg width='22' height='23' viewBox='0 0 22 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.66683 4.00195H18.3335C19.3418 4.00195 20.1668 4.81373 20.1668 5.80591V16.6296C20.1668 17.6218 19.3418 18.4336 18.3335 18.4336H3.66683C2.6585 18.4336 1.8335 17.6218 1.8335 16.6296V5.80591C1.8335 4.81373 2.6585 4.00195 3.66683 4.00195Z' stroke='%23F3941F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20.1668 5.80566L11.0002 12.1195L1.8335 5.80566' stroke='%23F3941F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }

    .input-group:focus-within > .input-group-prepend.icon-phone {
        background-image: url("data:image/svg+xml,%3Csvg width='21' height='22' viewBox='0 0 21 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.875 2.38574H6.125C5.1585 2.38574 4.375 3.15669 4.375 4.1077V17.8833C4.375 18.8343 5.1585 19.6053 6.125 19.6053H14.875C15.8415 19.6053 16.625 18.8343 16.625 17.8833V4.1077C16.625 3.15669 15.8415 2.38574 14.875 2.38574Z' stroke='%23F3941F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.5 16.1611H10.5087' stroke='%23F3941F' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    }

    .p-dropdown-trigger{
        width: 2rem;
    }

    .p-inputwrapper{
        border: 1px solid #DDDDDD;
        border-radius: 10px;
        height: 49px;
        font-size: 14px;
    }

    .p-dropdown:not(.p-disabled).p-focus{
        border: 1px solid #F3941F;
        box-shadow: unset;
        outline: none;
    }

    .p-dropdown:not(.p-disabled).p-focus .pi{
        color: #F3941F;
    }

    .p-dropdown-label{
        display: flex;
        font-size: 14px;
        align-items: center;
    }

    textarea.form-control{
        border-radius: 15px;
        resize: none;
        font-size: 14px;
        height: 155px;
    }

    textarea.form-control:focus{
        border: 1px solid #F3941F;
    }

    .captchaContainer canvas{
        border-color: #DDDDDD;
        background: #FFFFFF!important;
        border-radius: 15px;
    }

    .retryButton{
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.5166 9.00366H21.0166V4.66895' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.16699 5.93926C6.93308 5.20137 7.84255 4.61605 8.84348 4.2167C9.84442 3.81736 10.9172 3.61182 12.0006 3.61182C13.084 3.61182 14.1568 3.81736 15.1578 4.2167C16.1587 4.61605 17.0682 5.20137 17.8343 5.93926L21.0162 9.00415' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.4834 14.1133H2.9834V18.4473' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M17.8326 17.1782C17.0666 17.9161 16.1571 18.5014 15.1561 18.9007C14.1552 19.3001 13.0824 19.5056 11.999 19.5056C10.9156 19.5056 9.84281 19.3001 8.84187 18.9007C7.84093 18.5014 6.93146 17.9161 6.16538 17.1782L2.9834 14.1133' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        width: 44px;
        height: 32px;
        background-size: 24px 23.12px;
        background-color:#95C3E8;
        background-repeat: no-repeat;
        border-radius: 10px;
        background-position: center;
    }


    .card{
        padding: 30px 40px;
        border-radius: 40px;
        border: unset;
    }

    .card-text{
        background-color: transparent;
        border-radius: 0px;
        border: unset;
    }

    .heading-thin {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 1.25vw;
        line-height: 1.5;
    }

    .heading-bold{
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        font-size: 2.75vw;
        line-height: 1.25;
    }


    @media only screen and (max-width: 1120px) {
        padding-top: 0;
        padding-left: 10px;
        padding-right: 10px;

        .card-text{
            background-color: #FFFBF4;
            border-radius: 0px;
            border: unset;
            width: 100%;
        }

        .card{
            padding: 30px 15px;
            border-radius: 0;
            border: unset;
        }

        .container-fluid{
            padding: 0px 15px 15px 15px!important;
            height: 100%;
        }

        .item-text{
            display: none!important;
        }

        .heading-thin {
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: 2.25vh;
            line-height: 1.5;
        }
    
        .heading-bold{
            font-family: 'Roboto', sans-serif;
            font-weight: 900;
            font-size: 8.25vw;
            line-height: 1.25;
        }
    }
`;

const FormContact = () => {
    const refMessages = useRef(null);
    // const dispatch = useDispatch();
    const dimensions = useSelector((x) => x.dimensions.windowSize);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [departmentId, setDepartmentId] = useState(0);
    const [captcha, setCaptcha] = useState("");
    const [code, setCode] = useState("");
    const [listDepartment, setListDepartment] = useState([]);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [timeModified, setTimeModified] = useState(0);

    useEffect(() => {
        GetListDepartment();
    }, []);

    useEffect(() => {
        if (dimensions && Object.keys(dimensions).length > 0) {
            setWidth(dimensions.width);
            setHeight(dimensions.height);
        }
    }, [dimensions]);

    const GetListDepartment = () => {
        actions.getListDepartment().then(response => {
            if (response.status === enums.STATUS_RESPONSE_OK && response.data) {
                const {
                    data: {
                        status,
                        result,
                        message
                    }
                } = response;
                if (status === enums.STATUS_RESPONSE_OK) {
                    setListDepartment(result);
                } else {
                    // Swal.fire({
                    //     title: 'Bộ phận hỗ trợ',
                    //     text: data.message,
                    //     imageUrl: '/img/octopus/error.svg',
                    //     imageWidth: 109,
                    //     imageHeight: 98,
                    //     imageAlt: 'Error',
                    //     confirmButtonColor: '#76cc68',
                    //     showCloseButton: true
                    // })
                    console.log(`Error: ${message}`);
                }
            } 
        })
            .catch((err) => {
                // Swal.fire({
                //     title: 'Bộ phận hỗ trợ',
                //     text: err.toString(),
                //     imageUrl: '/img/octopus/error.svg',
                //     imageWidth: 109,
                //     imageHeight: 98,
                //     imageAlt: 'Error',
                //     confirmButtonColor: '#76cc68',
                //     showCloseButton: true
                // })
                console.log(`Error: ${err.toString()}`);
            })
    }

    const updateEnd = () => {
        setName("");
        setPhone("");
        setEmail("");
        setMessage("");
        setDepartmentId(0);
        setCaptcha("");
        setTimeModified(moment().valueOf());
    };

    const sendContact = (e) => {
        e.preventDefault();

        const _this = e.target;

        if (name === "") {
            refMessages.current.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập họ và tên !' });
            return;
        }

        if (email === "") {
            refMessages.current.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập email !' });
            return;
        }

        if (!fnc.isEmail(email)) {
            refMessages.current.show({ severity: 'error', summary: 'Lỗi', detail: 'Email không hợp lệ!' });
            return;
        }

        if (phone === "") {
            refMessages.current.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập số điện thoại !' });
            return;
        }

        if (!fnc.isVietnamesePhoneNumber(phone)) {
            refMessages.current.show({ severity: 'error', summary: 'Lỗi', detail: 'Số điện thoại không hợp lệ!' });
            return;
        }

        if (departmentId === 0) {
            refMessages.current.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng chọn bộ phận hỗ trợ!' });
            return;
        }

        if (message === "") {
            refMessages.current.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập nội dung !' });
            return;
        }


        const objSend = {
            name,
            phone,
            email,
            departmentId,
            message,
        };

        if (captcha !== code) {
            refMessages.current.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập đúng CAPTCHA !' });
            return;
            // dispatch();

        }

        actions.addContact(objSend, updateEnd)
    };

    const renderBody = () => {
        return (
            <div className="container-fluid container--fluid" style={{ width: `100%` }}>
                <div className="d-flex flex-column justify-content-center item-text" style={{ height: `100%`, position: `absolute`, width: `100%`, left: 0, zIndex: 0 }}>
                    <div className="d-flex" style={{ minHeight: 100, width: `100%`, backgroundColor: `#FFFBF4`, padding: `150px 0px` }}>
                    </div>
                </div>
                <div className="row" style={{ height: `100%`, zIndex: 999 }}>
                    <div className="col-md-5 d-flex justify-content-center align-items-center" style={{ padding: 0 }}>
                        <div className="card card-text text-center">
                            <div>
                                <b className="heading-bold">Octopus</b>
                            </div>
                            <div className="heading-thin">
                                Octopus – Sự lựa chọn thông minh<br />
                                Giúp giải quyết tất cả các vấn đề <br /> doanh nghiệp
                                đang gặp phải
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7" style={{ padding: 0 }}>
                        <div className="card">
                            <div className="row">
                                <div className="col-md-12">
                                    <b style={{ fontSize: 18 }}>Thông tin liên hệ</b>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <Messages ref={refMessages}></Messages>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row" style={{ marginTop: 15 }}>
                                        <div className="col-12">
                                            <span>Họ và tên</span>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: 7 }}>
                                        <div className="col-12">
                                            <div className="input-group">
                                                <div className="input-group-prepend icon-user d-flex align-items-center justify-content-center">
                                                </div>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Nhập họ và tên"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: 26.57 }}>
                                        <div className="col-12">
                                            <span>Email</span>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: 7 }}>
                                        <div className="col-12">
                                            <div className="input-group">
                                                <div className="input-group-prepend icon-mail d-flex align-items-center justify-content-center">

                                                </div>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Nhập email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: 26.57 }}>
                                        <div className="col-12">
                                            <span>Số điện thoại</span>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: 7 }}>
                                        <div className="col-12">
                                            <div className="input-group">
                                                <div className="input-group-prepend icon-phone d-flex align-items-center justify-content-center">

                                                </div>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Nhập số điện thoại"
                                                    value={phone}
                                                    onChange={(e) => setPhone(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: 26.57 }}>
                                        <div className="col-12">
                                            <span>Bộ phận hỗ trợ</span>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: 7 }}>
                                        <div className="col-12">
                                            <Dropdown
                                                optionLabel="departmentName"
                                                optionValue="departmentId"
                                                value={departmentId}
                                                options={listDepartment}
                                                filter
                                                onChange={(e) => setDepartmentId(e.value)}
                                                placeholder="Chọn Bộ phận"
                                                style={{ width: `100%` }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row" style={{ marginTop: 15 }}>
                                        <div className="col-12">
                                            <span>Nội dung</span>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: 7 }}>
                                        <div className="col-12">
                                            <textarea
                                                className="form-control"
                                                // style={{ height: 300 }}
                                                placeholder="Nhập nội dung"
                                                value={message}
                                                onChange={(e) => setMessage(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: 26.57 }}>
                                        <div className="col-12">
                                            <span>Vui lòng nhập CAPTCHA để tiếp tục</span>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: 7 }}>
                                        <div className="col-12">
                                            <ClientCaptcha
                                                captchaCode={(code) => {
                                                    setCode(code);
                                                }}
                                                width={200}
                                                charsCount={6}
                                                timeModified={timeModified}
                                            />
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: 23 }}>
                                        <div className="col-12">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Nhập Captcha"
                                                value={captcha}
                                                onChange={(e) => setCaptcha(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="text-center col-md-12" style={{ marginTop: 60 }}>
                                    <input
                                        type="image"
                                        src="/img/contact/send.svg"
                                        className="button-image-lp"
                                        onClick={sendContact}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    return (
        <ArticleContent height={height - 80}>
            {renderBody()}
        </ArticleContent>
    );
};

export default FormContact;
