import Error from '@/shared/_error';
import { ScrollTop } from 'primereact/scrolltop';
import { ScrollPanel } from 'primereact/scrollpanel';
import MenuDefault from '@/components/menu/menuDefault';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import * as actions from '@/redux/actions'
import styled from 'styled-components';

const MainLayout = styled.div`
    margin-top: 75px;
    width: 100%;
    height: 100%;
`


const LayoutMain = ({ children }) => {
    const dispatch = useDispatch();

    // const [windowSize, setWindowSize] = useState({
    //     width: 0,
    //     height: 0,
    // });

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            // setWindowSize({
            //     width: window.innerWidth,
            //     height: window.innerHeight,
            // });

            dispatch(actions.windowSizeChange({
                width: window.innerWidth,
                height: window.innerHeight,
            }))
        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);

    }, []); // Empty array ensures that effect is only run on mount



    return (
        <Fragment>
            <main className="main-layout">
                <MenuDefault />
                <MainLayout>
                    {children}
                </MainLayout>
                <ScrollTop threshold={100} className="custom-scrolltop" icon="pi pi-arrow-up" />
            </main>
            <Error />
        </Fragment>
    )
}
export default LayoutMain;